module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jan Wermeckes • Software-Entwickler","short_name":"Jan Wermeckes","description":"Ich bin Jan, 19 Jahre alt. Begeisterter Programmierer und Bergsteiger aus Deutschland. | Portfolio-Website Jan Wermeckes","lang":"de","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"static/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b26444b5cf1ecd02a33f098c3b467e10"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
